import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry.js";

import star from "../imgs/star.png";
import noise from "../imgs/grain.png";

const ThreeScene = ({ currentSection, gsap, isMenuOpen, swatch, path }) => {
  const canvasRef = useRef(null);
  const randomShapeRef = useRef();
  const dodecahedronRef = useRef();
  const gridRef = useRef([]);

  useEffect(() => {
    // Scene
    if (!isMenuOpen) {
      const scene = new THREE.Scene();
      if (swatch || path === "/privacy" || path === "/legal") {
        scene.background = new THREE.Color("#ffffff");
      } else {
        scene.background = new THREE.Color("#070707");
      }

      const textureLoader = new THREE.TextureLoader();
      const starTexture = textureLoader.load(star);
      let mouse = { x: 0, y: 0 };

      const onMouseMove = (event) => {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      };
      window.addEventListener("mousemove", onMouseMove);

      // Custom shaders for the points
      const vertexShader = `
      attribute float opacityShift;
      varying float vOpacityShift;
      uniform float pointSize;

      void main() {
        vOpacityShift = opacityShift;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = pointSize * 300.0 / length(gl_Position.xyz);
      }
    `;

      const fragmentShader = `
        varying float vOpacityShift;
        uniform float time;
        uniform sampler2D pointTexture;
    
        void main() {
            // Adjust for a 2-second cycle. With vOpacityShift, it will give a value that cycles from 0 to 2.
            float cycleTime = mod(time + vOpacityShift * 2.0, 2.0);
            float opacity;
    
            if (cycleTime < 1.0) {
                // transition from 0.2 to 1.0 in the first 1 second
                opacity = mix(0.01, 0.8, cycleTime);
            } else {
                // transition from 1.0 to 0.2 in the next 1 second
                opacity = mix(0.8, 0.01, cycleTime - 1.0);
            }
    
            vec4 texColor = texture2D(pointTexture, gl_PointCoord);
    
            // Discard the pixel if texture alpha is nearly zero to create a circular shape
            if (texColor.a < 0.1) discard;
    
            gl_FragColor = vec4(texColor.rgb, texColor.a * opacity); 
        }
    `;

      // ShaderMaterial
      const particlesMaterial = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        uniforms: {
          time: { value: 0 },
          pointTexture: { value: starTexture },
          pointSize: { value: window.innerWidth < 767 ? 1.2 : 0.06 },
        },
      });

      // Creating an "L" shaped object
      const createRandomShape = () => {
        const vertices = [];
        for (let i = 0; i < 10; i++) {
          vertices.push(
            new THREE.Vector3(
              (Math.random() - 0.5) * 2,
              (Math.random() - 0.5) * 2,
              (Math.random() - 0.5) * 2
            )
          );
        }

        const geometry = new ConvexGeometry(vertices);
        const material = new THREE.MeshStandardMaterial({ color: 0xffffff });
        const mesh = new THREE.Mesh(geometry, material);

        mesh.position.set(-2, -2, -1);

        scene.add(mesh);

        return mesh;
      };

      const randomShape = createRandomShape();
      randomShapeRef.current = randomShape;

      // Geometry
      const particlesGeometry = new THREE.BufferGeometry();
      const count = 5000;
      const positions = new Float32Array(count * 3);
      const opacityShifts = new Float32Array(count);
      const baseDistance = 3;

      for (let i = 0; i < count; i++) {
        const alpha = Math.random() * 2 * Math.PI;
        const beta = Math.random() * Math.PI;

        // Introduce randomness in distance from center
        const variation = 1;
        const randomDistance =
          baseDistance + (Math.random() * 3 - 1) * variation;

        positions[i * 3] = randomDistance * Math.sin(beta) * Math.cos(alpha);
        positions[i * 3 + 1] =
          randomDistance * Math.sin(beta) * Math.sin(alpha);
        positions[i * 3 + 2] = randomDistance * Math.cos(beta);

        opacityShifts[i] = Math.random();
      }

      particlesGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      particlesGeometry.setAttribute(
        "opacityShift",
        new THREE.BufferAttribute(opacityShifts, 1)
      );

      // Points
      const particles = new THREE.Points(particlesGeometry, particlesMaterial);
      scene.add(particles);

      // Creating a dodecahedron
      const geometry = new THREE.DodecahedronGeometry(1.5, 0); //
      const material = new THREE.MeshStandardMaterial({
        color: new THREE.Color("white"),
        transparent: false,
        opacity: 1, // Full opacity
        side: THREE.DoubleSide,
      });
      const dodecahedron = new THREE.Mesh(geometry, material);
      dodecahedronRef.current = dodecahedron;

      dodecahedron.position.x = 2; // Closer and central on x-axis
      dodecahedron.position.y = 10; // Closer and central on y-axis
      dodecahedron.position.z = -5; // In front of the camera on z-axis

      scene.add(dodecahedron);

      // Adding lights
      const ambientLight = new THREE.AmbientLight(0xffffff, 2);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(1, 1, 1);
      scene.add(directionalLight);

      // Camera
      const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      const camera = new THREE.PerspectiveCamera(
        90,
        sizes.width / sizes.height,
        1,
        1000
      );
      scene.add(camera);
      camera.position.z = 3;

      // Controls
      const controls = new OrbitControls(camera, canvasRef.current);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.minDistance = 3;
      controls.maxDistance = 50;
      controls.enableRotate = true;
      controls.minPolarAngle = Math.PI / 2;
      controls.maxPolarAngle = Math.PI / 2;
      controls.minAzimuthAngle = -Infinity; // No limit to left rotation
      controls.maxAzimuthAngle = Infinity; // No limit to right rotation
      controls.enableZoom = false;
      controls.mouseButtons.RIGHT = null;

      // Renderer
      const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true,
        alpha: false,
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(sizes.width, sizes.height);

      // Animation
      const clock = new THREE.Clock();
      const animate = () => {
        const elapsedTime = clock.getElapsedTime();
        particlesMaterial.uniforms.time.value = elapsedTime;
        particles.rotation.y = elapsedTime * 0.001;

        // Subtly move the camera based on mouse position
        camera.position.x += (mouse.x * 0.5 - camera.position.x) * 0.01; // Smoothing factor can be adjusted
        camera.position.y += (mouse.y * 0.5 - camera.position.y) * 0.01;

        // Slow rotation and drift
        dodecahedron.rotation.x += 0.0005;
        dodecahedron.rotation.y += 0.0005;
        dodecahedron.position.x += 0.00005;
        dodecahedron.position.y += 0.00005;
        dodecahedron.position.z += 0.00005;

        // Rotate the L shape
        randomShape.rotation.x += 0.0008;
        randomShape.rotation.y += 0.0008;
        randomShape.position.x += -0.00005;
        randomShape.position.y += -0.00005;
        randomShape.position.z += -0.00005;

        renderer.render(scene, camera);
        controls.update();
        requestAnimationFrame(animate);
      };
      animate();

      // Handle window resize
      const handleResize = () => {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();
        renderer.setSize(sizes.width, sizes.height);
        particlesMaterial.uniforms.pointSize.value =
          sizes.width < 767 ? 0.1 : 0.06;
      };
      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        renderer.dispose();
        particlesGeometry.dispose();
        particlesMaterial.dispose();
        starTexture.dispose();
      };
    } else {
      const loader = new THREE.TextureLoader();
      const noiseTexture = loader.load(noise);
      noiseTexture.wrapS = noiseTexture.wrapT = THREE.RepeatWrapping;
      noiseTexture.repeat.set(100, 100);

      const scene = new THREE.Scene();
      scene.background = new THREE.Color("#0c0c0c");
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        alpha: false,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.position.z = calculateCameraZPosition(window.innerWidth);

      const gridSize = 70;
      const planeSize = 3;
      const spacing = 0.05;
      const grid = new THREE.Group();

      const colorPalette = [
        new THREE.Color("#020202"),
        new THREE.Color("#060606"),
        new THREE.Color("#0f0f0f"),
      ];

      for (let i = -gridSize; i <= gridSize; i += planeSize + spacing) {
        for (let j = -gridSize; j <= gridSize; j += planeSize + spacing) {
          const geometry = new THREE.PlaneGeometry(planeSize, planeSize);
          const material = new THREE.MeshBasicMaterial({
            map: noiseTexture,
            transparent: true,
            opacity: 0.8,
            color:
              colorPalette[Math.floor(Math.random() * colorPalette.length)],
            side: THREE.DoubleSide,
          });
          const plane = new THREE.Mesh(geometry, material);
          plane.userData = {
            currentColor: material.color.clone(),
            targetColor: new THREE.Color().copy(material.color),
            lastChangeTime: performance.now() - Math.random() * 40000 - 20000,
            changeInterval: Math.random() * 40000 + 20000,
          };
          plane.position.set(i, j, 0);
          grid.add(plane);
          gridRef.current.push(plane);
        }
      }

      scene.add(grid);

      const updateColors = () => {
        const now = performance.now();
        gridRef.current.forEach((plane) => {
          if (
            now - plane.userData.lastChangeTime >
            plane.userData.changeInterval
          ) {
            plane.userData.targetColor.copy(
              colorPalette[Math.floor(Math.random() * colorPalette.length)]
            );
            plane.userData.lastChangeTime = now;
            plane.userData.changeInterval = Math.random() * 40000 + 20000;
          }
          plane.material.color.lerp(plane.userData.targetColor, 0.1);
        });
      };

      const animate = () => {
        requestAnimationFrame(animate);
        updateColors();
        renderer.render(scene, camera);
      };

      animate();

      function calculateCameraZPosition(width) {
        const baseWidth = 1320;
        const basePositionZ = 50;
        if (width <= baseWidth) {
          return basePositionZ;
        } else {
          return basePositionZ - (width - baseWidth) / 70;
        }
      }

      const handleResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.position.z = calculateCameraZPosition(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        grid.children.forEach((mesh) => {
          mesh.geometry.dispose();
          mesh.material.dispose();
        });
        gridRef.current = [];
        window.removeEventListener("resize", handleResize);
        scene.clear();
        renderer.dispose();
      };
    }
  }, [isMenuOpen, swatch, path]);

  useEffect(() => {
    if (swatch || path === "/privacy" || path === "/legal") {
    } else {
      switch (currentSection) {
        case 1:
          if (randomShapeRef.current && dodecahedronRef.current) {
            gsap.to(randomShapeRef.current.position, {
              duration: 1,
              x: 2,
              ease: "power1.inOut",
            });
            gsap.to(dodecahedronRef.current.position, {
              duration: 1,
              x: 2,
              ease: "power1.inOut",
            });
          }
          break;
        case 2:
          if (randomShapeRef.current && dodecahedronRef.current) {
            gsap.to(randomShapeRef.current.position, {
              duration: 1,
              x: -2,
              y: 2,
              ease: "power1.inOut",
            });
            gsap.to(dodecahedronRef.current.position, {
              duration: 1,
              x: 4,
              y: -2,
              ease: "power1.inOut",
            });
          }
          break;
        case 3:
          if (randomShapeRef.current && dodecahedronRef.current) {
            gsap.to(randomShapeRef.current.position, {
              duration: 1,
              x: 4,
              y: -2,
              ease: "power1.inOut",
            });
            gsap.to(dodecahedronRef.current.position, {
              duration: 1,
              x: -2,
              y: 2,
              ease: "power1.inOut",
            });
          }
          break;
        default:
          if (randomShapeRef.current && dodecahedronRef.current) {
            gsap.to(randomShapeRef.current.position, {
              duration: 1,
              x: -2,
              y: -2,
              ease: "power1.inOut",
            });
            gsap.to(dodecahedronRef.current.position, {
              duration: 1,
              x: 2,
              y: 3,
              ease: "power1.inOut",
            });
          }
      }
    }
  }, [currentSection, gsap, path, swatch]);

  return (
    <canvas
      ref={canvasRef}
      className="webgl absolute"
      style={{ pointerEvents: "auto" }}
    ></canvas>
  );
};

export default ThreeScene;
