import React from "react";
import { Link } from "react-router-dom";
import BackArrowIcon from "../svgs/LeftArrowIcon";

const NotFoundPage = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center justify-center w-[90%] h-[90%] text-sm gap-3">
        <h1 className="text-7xl font-bold text-red-500 mb-10">404</h1>
        <h2 className="text-black">Page Not Found</h2>
        <p className="text-black max-w-40 md:max-w-80 text-center">
          Sorry, the page you are looking for does not exist.
        </p>
        <div className="group flex gap-1 cursor-pointer items-center">
          <Link
            className="font-semibold group-hover:-translate-x-1 transition-transform duration-200 transform"
            to="/"
          >
            Go Back
          </Link>
          <BackArrowIcon className="w-4 h-4 mt-[.066rem] group-hover:translate-x-1 transition-transform duration-200 transform" />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
