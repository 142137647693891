import React, { useEffect, useRef } from "react";
import styles from "../css/Header.module.css";

const SoundButton = ({
  isMenuOpen,
  isSoundOn,
  setIsSoundOn,
  audioRef,
  audioCtx,
  animationRunning,
  setAnimationRunning,
  sourceCreated,
  setSourceCreated,
  playSoundAnchors,
  stopSoundAnchors,
  swatch,
  path,
}) => {
  const animationFrameRef = useRef();
  const paths = useRef([]);
  const pathStates = useRef(
    Array(5)
      .fill()
      .map(() => ({
        scaleDirection: "increasing",
        translationDirection: "decreasing",
        delay: Math.random() * 2000,
        pauseDuration: Math.random() * 6000,
        activeDuration: 4000,
        nextStartTime: 0,
      }))
  );

  const startAnimations = () => {
    if (animationRunning) {
      animatePaths();
    }
  };

  const stopAnimations = () => {
    cancelAnimationFrame(animationFrameRef.current);
  };

  const animatePaths = () => {
    if (animationRunning) {
      const currentTime = new Date().getTime();
      paths.current.forEach((path, index) => {
        const state = pathStates.current[index];
        if (path) {
          if (currentTime >= state.nextStartTime) {
            let progress =
              (currentTime - state.nextStartTime) % state.activeDuration;
            progress /= state.activeDuration / 2;
            if (progress > 1) progress = 2 - progress;

            let scaleValue;
            if (state.scaleDirection === "increasing") {
              scaleValue = 0.3 + progress * (0.9998 - 0.3);
              if (progress >= 1) state.scaleDirection = "decreasing";
            } else {
              scaleValue = 0.9998 - progress * (0.9998 - 0.3);
              if (progress >= 1) state.scaleDirection = "increasing";
            }

            let translationValue;
            if (state.translationDirection === "decreasing") {
              translationValue = 4.2 - progress * (4.2 - 0.012);
              if (progress >= 1) state.translationDirection = "increasing";
            } else {
              translationValue = 0.012 + progress * (4.2 - 0.012);
              if (progress >= 1) state.translationDirection = "decreasing";
            }

            path.style.transform = `matrix(1, 0, 0, ${scaleValue.toFixed(
              4
            )}, 0, ${translationValue.toFixed(3)})`;

            if (currentTime - state.nextStartTime >= state.activeDuration) {
              state.nextStartTime = currentTime + state.pauseDuration;
            }
          }
        }
      });

      animationFrameRef.current = requestAnimationFrame(animatePaths);
    }
  };
  const resetPaths = () => {
    paths.current.forEach((path, index) => {
      path.style.transform = "matrix(1, 0, 0, 0.3, 0, 4.2)";
      pathStates.current[index] = {
        scaleDirection: "increasing",
        translationDirection: "decreasing",
        delay: Math.random() * 2000,
        pauseDuration: Math.random() * 2000,
        activeDuration: 4000,
        nextStartTime: new Date().getTime() + Math.random() * 2000,
      };
    });
  };
  const toggleSound = () => {
    if (isSoundOn) {
      audioRef.current.pause();
      stopAnimations();
      resetPaths();
    } else {
      audioCtx.resume().then(() => {
        audioRef.current.play();
        setAnimationRunning(true);
        startAnimations();
      });
    }
    setIsSoundOn(!isSoundOn);
  };

  useEffect(() => {
    if (audioRef.current && audioCtx && !sourceCreated) {
      const source = audioCtx.createMediaElementSource(audioRef.current);
      source.connect(audioCtx.destination);
      setSourceCreated(true);
    }

    if (audioRef.current) {
      audioRef.current.loop = true;
    }

    paths.current.forEach((path) => {
      path.style.transition = "transform 0.5s ease";
    });

    resetPaths();
    return () => {
      stopAnimations();
    };
  }, [audioCtx, audioRef, setSourceCreated, sourceCreated]);

  useEffect(() => {
    if (animationRunning) {
      startAnimations();
    } else {
      stopAnimations();
    }

    return () => stopAnimations();
  }, [animationRunning]);

  return (
    <button
      className={`${styles.buttonButton} ${styles.soundButton} ${
        styles.headerSound
      } ${isMenuOpen ? styles.soundButtonMenu : ""} ${
        swatch || path === "/privacy" || path === "/legal"
          ? styles.soundButtonInner
          : ""
      }`}
      onClick={toggleSound}
      onMouseOver={playSoundAnchors}
      onMouseLeave={stopSoundAnchors}
    >
      <svg
        viewBox="0 0 17 12"
        className={`${styles.soundButtonIcon} ${
          swatch ? styles.soundButtonIconInner : ""
        }`}
      >
        <g>
          {[...Array(5)].map((_, i) => (
            <path
              key={i}
              ref={(el) => (paths.current[i] = el)}
              d={`M${i * 4 + 0.7},12V0`}
              style={{
                transformOrigin: "0px 0px",
                translate: "none",
                rotate: "none",
                scale: "none",
                transform: "matrix(1, 0, 0, 0.3, 0, 4.2)",
              }}
            ></path>
          ))}
        </g>
      </svg>
      <div className={styles.soundLabel} style={{ visibility: "inherit" }}>
        {isSoundOn ? "Sound On" : "Sound Off"}
      </div>
    </button>
  );
};

export default SoundButton;
