import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CookieBanner = ({ onAccept, handleCookieDeny }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setIsVisible(false);
    onAccept();
  };
  const handleDeny = () => {
    setIsVisible(false);
    handleCookieDeny();
  };

  if (!isVisible) return null;

  return (
    <>
      <div className="absolute top-0 left-0 w-full h-screen transition duration-200 ease-linear bg-black bg-opacity-60 z-[999]"></div>
      <div className="cookie-banner shadow-lg shadow-white flex md:flex-row flex-col gap-4 lg:gap-0 justify-between items-center fixed bottom-0 left-0 w-full bg-[rgba(0,_0,_0,_0.99)] z-[1000]">
        <div className="lg:w-[60%] xl:w-[55%] w-[95%]">
          <div className="overflow-hidden lg:ml-8 m-[1.25em_0_.625em_2em] mx-4">
            <div className="text-[.813em] leading-6 float-left clear-both text-left text-white">
              By clicking “Accept All Cookies”, you agree to the storing of
              cookies on your device to enhance site navigation, analyze site
              usage, and assist in our marketing efforts.
            </div>
          </div>
        </div>
        <div className="flex gap-4 w-full justify-end mr-10 md:mr-0 md:justify-center">
          <button
            onClick={handleDeny}
            className=" p-[12px_10px] leading-5 min-w-32 font-semibold text-[.813em] bg-[#34696E] border-[#34696E] hover:opacity-70 text-white transition duration-200 ease-linear border-none cursor-pointer"
          >
            Reject All
          </button>
          <button
            onClick={handleAccept}
            className=" p-[12px_10px] leading-5 min-w-32 font-semibold text-[.813em] bg-[#34696E] border-[#34696E] hover:opacity-70 text-white transition duration-200 ease-linear border-none cursor-pointer"
          >
            Accept All Cookies
          </button>
        </div>
        <div>
          <button
            onClick={handleDeny}
            className="text-white top-0.5 md:top-1/3 lg:top-[42.69%] xl:top-[33%] right-3.5 md:right-6  m-auto absolute"
          >
            X
          </button>
        </div>
      </div>
    </>
  );
};

export default CookieBanner;
