import React, { useState, useEffect, useRef, useMemo } from "react";
import { gsap } from "gsap";
import Header from "./Header";
import Footer from "./Footer";
import "./css/app.css";
import ThreeComponent from "./parts/ThreeComponent";
import Loading from "./Loading";
import Borders from "./Borders";
import styles from "./css/Loading.module.css";
import featuredWorks from "./data/featuredWorks";
import CookieBanner from "./CookieBanner";
import Cookies from "js-cookie";
import LMPIcon from "./svgs/LMP";
import SwiperComponent from "./components/SwiperComponent";
import overlay from "./imgs/overlay.webp";
import { UAParser } from "ua-parser-js";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LegalDisclaimer from "./components/LegalDisclaimer";
import HomeIconLink from "./components/HomeIconLink";
import usePageTracking from "./UsePageTracking";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import NotFoundPage from "./components/NotFoundPage";
import RedirectTo404 from "./components/RedirectTo404";

const SectionOne = ({ isActive }) => {
  useEffect(() => {
    const headingElement = document.querySelector(".main-section h2");
    if (headingElement) {
      headingElement.dataset.scrambled = "false";
    }
  }, []);

  return (
    <div
      className={`main-section ${
        isActive ? "fadeInUp" : ""
      } pb-12 flex flex-col items-center`}
    >
      <div className="pl-4 md:pl-0 w-full md:w-1/2">
        <div className="pb-4">
          <LMPIcon style={{ width: "200px", height: "auto" }} />
        </div>
      </div>
      <h2
        className={`${styles.headingMain} section-one-heading`}
        data-scrambled="false"
        data-value="Media Production"
      >
        Media Production
      </h2>
      <p
        className={`${styles.subHeading} section-one-subheading`}
        data-value="Your vision, our creation"
      >
        Your vision, our creation.
      </p>
    </div>
  );
};

const SectionTwo = ({
  isActive,
  deviceType,
  playSoundLinks,
  stopSoundLinks,
}) => {
  const baseDelay = 0;
  const [hoveredWork, setHoveredWork] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const customPaddingClasses = ["md:pl-4", "md:pl-40", "md:pl-8", "md:pl-0"];

  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenHeight);

    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  useEffect(() => {
    if (hoveredWork) {
      setIsTransitioning(true);
      setImageSrc(hoveredWork.img);

      const timeoutId = setTimeout(() => {
        setIsTransitioning(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setImageSrc("");
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [hoveredWork]);

  const handleMouseEnter = (work) => {
    if (deviceType === "desktop") {
      setHoveredWork(work);
    }
  };

  const handleMouseLeave = () => {
    if (deviceType === "desktop") {
      setHoveredWork(null);
    }
  };

  return (
    <div
      id="featured"
      className={`${isActive ? "fadeInUp" : ""} section-two w-[88%]`}
    >
      <div
        style={{ animationDelay: `${isActive ? baseDelay : 0}s` }}
        className={`${isActive ? "fadeInUp" : ""} opacity-0`}
      >
        <p
          className={`w-[42.69%] ml-2 lg:ml-0 lg:w-[33.33%] text-sm lg:text-2xl font-normal border-b-2 border-white mb-4 leading-7 lg:leading-[3rem_!important]`}
        >
          Featured Work
        </p>
      </div>
      {featuredWorks.map((work, index) => (
        <div
          key={work.name}
          style={{
            animationDelay: `${isActive ? baseDelay + 0.3 + index * 0.3 : 0}s`,
          }}
          className={`${isActive ? "fadeInUp" : ""} opacity-0`}
        >
          <p
            className={`font-bold ${
              screenHeight < 800 && deviceType === "desktop"
                ? "text-[4.269vw]"
                : "text-[10vw] lg:text-[6.5vw]"
            }  ml-4 ${
              deviceType === "desktop"
                ? "hover:scale-110 hover:text-red-500 hover:translate-x-8"
                : ""
            } cursor-pointer transform duration-200 ease-in-out w-fit lg:leading-tight ${
              index >= 0 ? customPaddingClasses[index - 0] : ""
            } ${deviceType === "tablet" ? "-mb-10 lg:-mb-6" : "lg:-mb-6 mb-1"}`}
            onMouseEnter={() => handleMouseEnter(work)}
            onMouseLeave={handleMouseLeave}
          >
            <a
              className="p-1"
              href={work.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={work.label}
              onMouseEnter={playSoundLinks}
              onMouseLeave={stopSoundLinks}
            >
              {work.name}
            </a>
          </p>
        </div>
      ))}
      <div
        className={`fixed top-0 w-[40%] h-full [transition:right_0.5s_ease-in-out] ${
          hoveredWork ? "-right-40" : "-right-full"
        }`}
        style={{ backgroundImage: `url(${imageSrc})`, backgroundSize: "cover" }}
      ></div>
    </div>
  );
};

const SectionThree = ({
  isActive,
  isDesktop,
  isSmallHeight,
  description,
  setDescription,
  browserName,
  deviceType,
  isLoadingComplete,
}) => {
  const [text, setText] = useState("Website Design & Development");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200);
  const [isTypingStarted, setIsTypingStarted] = useState(false);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, [description]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTypingStarted(true);
      setIsDeleting(true);
    }, 2400);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const texts = [
      "Web Design & Development",
      "Photography",
      "Videography",
      "Drone Content",
      "PR",
      "Social Media Management",
      "Graphic Design",
      "SEO",
    ];
    if (!isTypingStarted) return;

    let timer;
    const current = loopNum % texts.length;
    const fullText = texts[current];

    const handleTyping = () => {
      setText(
        isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1)
      );

      setTypingSpeed(isDeleting ? 100 : 100);

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), 2000);
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, typingSpeed, isTypingStarted]);

  return (
    <div
      id="services"
      className={`${
        isActive ? "fadeInUp" : ""
      } section-three w-[90%] h-[90%] flex flex-col justify-start gap-40`}
    >
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col lg:flex-row items-center lg:gap-4 z-10 pb-4 lg:pb-0">
          <h2
            className={`lg:pl-4 font-bold max-w-[63rem] ${
              deviceType === "tablet" ? "text-5xl" : "text-lg lg:text-6xl"
            }`}
          >
            We offer
          </h2>
          <h2
            className={`transform text-center font-bold tracking-tight text-wrap ${
              deviceType === "tablet"
                ? "text-5xl"
                : "text-[1.25rem] lg:text-6xl"
            }`}
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.8 }}
          >
            {text}
            <span className={`${styles.cursor}`}>|</span>
          </h2>
        </div>
        <div className="h-full w-full flex flex-col-reverse lg:flex-row items-center text-white justify-evenly">
          <div
            className={`relative ${
              browserName === "firefox" ? "pt-4" : "pt-0"
            } lg:pt-0 w-full ${
              deviceType === "tablet"
                ? "lg:max-w-[26rem] min-h-[252px]"
                : "lg:max-w-144 max-h-[168px] min-h-[160px]"
            } ${
              deviceType === "tablet" && isDesktop ? "flex items-center" : ""
            }`}
          >
            <img
              className={`absolute z-0 lg:left-0 lg:w-[100%] ${
                deviceType === "tablet"
                  ? ""
                  : isSmallHeight
                  ? "lg:-top-[95%]"
                  : "lg:-bottom-28"
              } ${
                deviceType === "tablet" && isDesktop ? "h-[500px]" : "-bottom-8"
              } ${deviceType === "tablet" && !isDesktop ? "-bottom-16" : ""}`}
              src={isLoadingComplete ? overlay : ""}
              alt=""
            />
            <p
              className={`px-4 description-fade text-balance text-center z-10 relative ${
                visible ? "description-visible" : ""
              } ${
                deviceType === "tablet"
                  ? "font-semibold text-lg"
                  : "lg:font-semibold text-sm lg:text-lg"
              }`}
            >
              {description}
            </p>
          </div>
          {isLoadingComplete && (
            <SwiperComponent
              isDesktop={isDesktop}
              isSmallHeight={isSmallHeight}
              browserName={browserName}
              deviceType={deviceType}
              setDescription={(desc) => {
                setVisible(false);
                setTimeout(() => {
                  setDescription(desc);
                  setVisible(true);
                }, 200);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SectionFour = ({ isActive }) => (
  <div
    id="contact"
    className={`section-four ${isActive ? "fadeInUp" : ""}`}
  ></div>
);

function App() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const longestAnimationDuration = 1000;
  const [contentOpacity, setContentOpacity] = useState(0);
  const appContentRef = useRef(null);
  const loadingRef = useRef(null);
  const bordersRef = useRef(null);
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);
  const touchStartTime = useRef(null);
  const swipeThreshold = 90;
  const tapMovementThreshold = 10;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const isSmallHeight = useState(window.innerHeight <= 900);
  const [description, setDescription] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [classNames, setClassNames] = useState("");
  const [styleOpacity, setStyleOpacity] = useState(1);
  const [styleVisibility, setStyleVisibility] = useState("inherit");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const audioRef = useRef(null);
  const audioCtx = new AudioContext();
  const [animationRunning, setAnimationRunning] = useState(false);
  const [showGlitch, setShowGlitch] = useState(false);
  const edgeThresholdPercentage = 10;
  const [sourceCreated, setSourceCreated] = useState(false);
  const [currentSectionOpacity, setCurrentSectionOpacity] = useState(1);
  const hoverLinks = useRef(null);
  const hoverAnchors = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const [swatch, setSwatch] = useState(false);
  usePageTracking();

  useEffect(() => {
    if (isLoadingComplete) {
      audioRef.current = new Audio("static/songs/soonshort.mp3");
      hoverLinks.current = new Audio("static/songs/hover.mp3");
      hoverAnchors.current = new Audio("static/songs/hover1.mp3");
      ReactGA.initialize("G-1WDJPVT5BL");
      ReactGA.send("pageview");
    } else {
      audioRef.current = null;
    }
  }, [isLoadingComplete]);

  const playSoundLinks = () => {
    if (hoverLinks.current) {
      hoverLinks.current.play();
    }
  };

  const stopSoundLinks = () => {
    if (hoverLinks.current) {
      hoverLinks.current.pause();
      hoverLinks.current.currentTime = 0;
    }
  };

  const playSoundAnchors = () => {
    if (hoverAnchors.current) {
      hoverAnchors.current.play();
    }
  };

  const stopSoundAnchors = () => {
    if (hoverAnchors.current) {
      hoverAnchors.current.pause();
      hoverAnchors.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      setShowGlitch(true);

      setTimeout(() => {
        setShowGlitch(false);
      }, 100);
    } else {
      setShowGlitch(true);

      setTimeout(() => {
        setShowGlitch(false);
      }, 100);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    const browser = result.browser.name
      ? result.browser.name.replace(/ /g, "").toLowerCase()
      : "unknownbrowser";
    const osName = result.os.name
      ? result.os.name.replace(/ /g, "").toLowerCase()
      : "unknownos";
    const deviceType = result.device.type || "desktop";

    setBrowserName(browser);
    setDeviceType(deviceType);
    setClassNames(`${browser} ${osName} ${deviceType}`);
  }, []);

  useEffect(() => {
    document.documentElement.className = classNames;
    if (path === "/privacy" || path === "/legal" || path === "/404") {
      document.body.className = "";
      setSwatch(true);
    } else {
      document.body.className = "overflow-hidden";
      setSwatch(false);
    }
    return () => {
      document.documentElement.className = "";
    };
  }, [classNames, path]);

  const sections = [
    () => <SectionOne isActive={currentSection === 0} />,
    () => (
      <SectionTwo
        isActive={currentSection === 1}
        isDesktop={isDesktop}
        setIsDesktop={setIsDesktop}
        deviceType={deviceType}
        isSmallHeight={isSmallHeight}
        playSoundLinks={playSoundLinks}
        stopSoundLinks={stopSoundLinks}
      />
    ),
    () => (
      <SectionThree
        isActive={currentSection === 2}
        isDesktop={isDesktop}
        isSmallHeight={isSmallHeight}
        description={description}
        setDescription={setDescription}
        browserName={browserName}
        deviceType={deviceType}
        isLoadingComplete={isLoadingComplete}
      />
    ),
    () => <SectionFour isActive={currentSection === 3} />,
  ];

  const scrambleText = (element) => {
    if (swatch || path === "/privacy" || path === "/legal" || path === "/404") {
      return;
    }
    if (element.dataset.scrambled === "true") {
      return;
    }
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+{}[]|;:',.<>?/`~";

    const originalText = element.dataset.value || element.textContent;

    let scrambleTimeline = gsap.timeline();

    const charSpans = Array.from(originalText).map((char) => {
      const span = document.createElement("span");
      span.textContent = char;
      element.appendChild(span);
      return span;
    });

    element.textContent = "";

    charSpans.forEach((span) => element.appendChild(span));

    charSpans.forEach((span, i) => {
      gsap.to(span, {
        duration: 0.06,
        repeat: -1,
        repeatDelay: 0.06,
        ease: "none",
        onUpdate: () => {
          span.textContent = chars[Math.floor(Math.random() * chars.length)];
        },
      });

      scrambleTimeline.to(
        {},
        {
          duration: 0.15,
          onComplete: () => {
            gsap.killTweensOf(span);
            span.textContent = originalText.charAt(i);
          },
        },
        "+=0.15"
      );
    });

    scrambleTimeline.fromTo(
      element,
      { opacity: 0 },
      { opacity: 1, duration: 0.5 },
      0
    );

    scrambleTimeline.eventCallback("onComplete", () => {
      element.dataset.scrambled = "true";
    });

    return scrambleTimeline;
  };

  const scrambleSubHeading = (element, shouldScramble) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+{}[]|;:',.<>?/`~";

    // Initialize by splitting text into spans if not already done
    if (!element.dataset.initialized) {
      const originalText = element.dataset.value;
      element.innerHTML = "";
      originalText.split("").forEach((char) => {
        const span = document.createElement("span");
        span.textContent = char;
        element.appendChild(span);
      });
      element.dataset.initialized = "true";
    }

    let iteration = 0;
    const interval = setInterval(() => {
      element.childNodes.forEach((span, index) => {
        if (index < iteration) {
          span.textContent = element.dataset.value.charAt(index);
        } else {
          span.textContent = chars[Math.floor(Math.random() * chars.length)];
        }
      });

      iteration += 1 / 3;
      if (iteration >= element.dataset.value.length) {
        clearInterval(interval);
        if (!shouldScramble) {
          // Reset to original text
          element.childNodes.forEach((span, index) => {
            span.textContent = element.dataset.value.charAt(index);
          });
        }
      }
    }, 30);
  };

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (consent === "true") {
      setShowLoading(false);
      setShowCookieBanner(false);
      setContentOpacity(1);
      setIsLoadingComplete(true);
    } else if (!consent && isLoadingComplete) {
      setTimeout(() => setShowCookieBanner(true), 3000);
    }
  }, [isLoadingComplete]);

  useEffect(() => {
    if (swatch || path === "/privacy" || path === "/legal" || path === "/404") {
      return;
    }
    if (isLoadingComplete && currentSection === 0) {
      const sectionOneHeading = document.querySelector(".section-one-heading");
      const subHeadingElement = document.querySelector(
        ".section-one-subheading"
      );

      if (sectionOneHeading) {
        sectionOneHeading.dataset.scrambled = "false";
        scrambleText(sectionOneHeading);
      }

      const handleMouseMove = () => scrambleSubHeading(subHeadingElement, true);
      const handleMouseStop = () =>
        scrambleSubHeading(subHeadingElement, false);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseout", handleMouseStop);
      window.addEventListener("mouseover", handleMouseStop);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseout", handleMouseStop);
        window.removeEventListener("mouseover", handleMouseStop);
      };
    }
  }, [isLoadingComplete, currentSection, swatch, path]);

  const handleLoadingComplete = () => {
    const tl = gsap.timeline();
    const firstSectionHeading = document.querySelector(".section-one-heading");

    if (bordersRef.current && bordersRef.current.triggerGlitchEffect) {
      bordersRef.current.triggerGlitchEffect();
    }

    const loadingWrapper = loadingRef.current.querySelector(
      `.${styles.loadingWrapperContainer}`
    );

    tl.to(
      loadingWrapper,
      {
        scale: 0,
        opacity: 0,
        duration: 0.5,
        ease: "power3.inOut",
      },
      "start"
    )

      .to(
        loadingRef.current,
        {
          opacity: 0,
          duration: 1,
          ease: "power3.inOut",
        },
        "start"
      )

      .to(
        appContentRef.current,
        {
          opacity: 1,
          duration: 2,
        },
        "start"
      );

    tl.add(scrambleText(firstSectionHeading), "start+=0.5");

    setIsLoadingComplete(true);
    setTimeout(() => setShowLoading(false), 2000);
  };

  const handleCookieAccept = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setShowCookieBanner(false);
  };
  const handleCookieDeny = () => {
    setShowCookieBanner(false);
  };

  useEffect(() => {
    const onScroll = (e) => {
      if (isMenuOpen) {
        e.preventDefault();
        return;
      }
      if (isAnimating || showCookieBanner) return;

      e.preventDefault();

      const direction = e.deltaY > 0 ? 1 : -1;

      const newSection = currentSection + direction;
      if (newSection >= 0 && newSection < sections.length) {
        setIsAnimating(true);
        setCurrentSection(newSection);

        setTimeout(() => {
          setIsAnimating(false);
        }, longestAnimationDuration);
      }
    };

    window.addEventListener("wheel", onScroll, { passive: false });
    return () => window.removeEventListener("wheel", onScroll);
  }, [
    currentSection,
    isAnimating,
    sections.length,
    showCookieBanner,
    isMenuOpen,
    path,
  ]);

  const scrollToNextSection = (e) => {
    if (isMenuOpen) {
      e.preventDefault();
      return;
    } else if (currentSection < sections.length - 1) {
      setCurrentSection(currentSection + 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchElement = e.target;

    if (touchElement.tagName === "A" || touchElement.closest("a")) {
      return;
    }
    const touchPosY = e.touches[0].clientY;
    const threshold = window.innerHeight * (edgeThresholdPercentage / 100);

    if (touchPosY < threshold || touchPosY > window.innerHeight - threshold) {
      return;
    }

    touchStartY.current = touchPosY;
    touchStartTime.current = Date.now();
  };

  const handleTouchMove = (e) => {
    if (touchStartY.current === null) {
      return;
    }

    touchEndY.current = e.touches[0].clientY;
  };

  useEffect(() => {
    const handleTouchEnd = () => {
      if (isAnimating || showCookieBanner || touchStartY.current === null)
        return;

      const touchDistance = Math.abs(touchStartY.current - touchEndY.current);
      const touchDuration = Date.now() - touchStartTime.current;

      if (touchDistance < tapMovementThreshold) {
        touchStartY.current = null;
        return;
      }

      if (touchDistance > swipeThreshold && touchDuration > 150) {
        const direction = touchStartY.current > touchEndY.current ? 1 : -1;
        const newSection = currentSection + direction;

        if (newSection >= 0 && newSection < sections.length) {
          setIsAnimating(true);
          setCurrentSection(newSection);
          setTimeout(() => {
            setIsAnimating(false);
          }, longestAnimationDuration);
        }
      }
      touchStartY.current = null;
      touchStartTime.current = null;
    };
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [currentSection, isAnimating, showCookieBanner, sections.length]);

  const strokeColor = useMemo(() => {
    if (
      (swatch && isLoadingComplete) ||
      (path === "/privacy" && isLoadingComplete) ||
      (path === "/legal" && isLoadingComplete) ||
      (path === "/404" && isLoadingComplete)
    ) {
      return "black";
    } else {
      return "white";
    }
  }, [swatch, path, isLoadingComplete]);

  return (
    <div className={styles.appWrapper}>
      {!isMenuOpen && <Borders ref={bordersRef} strokeColor={strokeColor} />}
      <div ref={loadingRef}>
        {showLoading && (
          <Loading
            onLoadingComplete={handleLoadingComplete}
            deviceType={deviceType}
          />
        )}
      </div>
      <div ref={appContentRef} style={{ opacity: contentOpacity }}>
        {showGlitch && (
          <div
            className={styles.glitchOverlay}
            style={{ display: "block" }}
          ></div>
        )}
        <Header
          deviceType={deviceType}
          styleOpacity={styleOpacity}
          styleVisibility={styleVisibility}
          setStyleOpacity={setStyleOpacity}
          setStyleVisibility={setStyleVisibility}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          setCurrentSection={setCurrentSection}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          audioCtx={audioCtx}
          audioRef={audioRef}
          animationRunning={animationRunning}
          setAnimationRunning={setAnimationRunning}
          setShowGlitch={setShowGlitch}
          sourceCreated={sourceCreated}
          setSourceCreated={setSourceCreated}
          setCurrentSectionOpacity={setCurrentSectionOpacity}
          playSoundAnchors={playSoundAnchors}
          stopSoundAnchors={stopSoundAnchors}
          location={location}
          navigate={navigate}
          setSwatch={setSwatch}
          swatch={swatch}
        />

        <div className="relative">
          {showCookieBanner && (
            <CookieBanner
              onAccept={handleCookieAccept}
              handleCookieDeny={handleCookieDeny}
            />
          )}
          <div
            id="canvas-container"
            className="top-0 left-0 w-screen h-lvh z-[-1] fixed pointer-events-none"
          >
            <ThreeComponent
              currentSection={currentSection}
              gsap={gsap}
              isMenuOpen={isMenuOpen}
              swatch={swatch}
              path={path}
            />
          </div>
          <main
            className="app absolute w-full h-screen overflow-hidden z-40"
            style={{ opacity: styleOpacity, visibility: styleVisibility }}
          >
            {deviceType === "desktop" && !swatch && (
              <div className="absolute inset-0 overflow-hidden">
                <div
                  className={`${styles.jumbo} absolute -inset-[10px] opacity-50`}
                ></div>
              </div>
            )}
            <Routes>
              <Route
                path="/privacy"
                element={
                  <>
                    <Helmet>
                      <link
                        rel="canonical"
                        href="https://www.theliftedmedia.com/privacy"
                      />
                      <meta name="robots" content="noindex" />
                    </Helmet>
                    <PrivacyPolicy
                      navigate={navigate}
                      setCurrentSection={setCurrentSection}
                      swatch={swatch}
                    />
                  </>
                }
              />
              <Route
                path="/legal"
                element={
                  <>
                    <Helmet>
                      <link
                        rel="canonical"
                        href="https://www.theliftedmedia.com/legal"
                      />
                      <meta name="robots" content="noindex" />
                    </Helmet>
                    <LegalDisclaimer
                      navigate={navigate}
                      setCurrentSection={setCurrentSection}
                      swatch={swatch}
                    />
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <Helmet>
                      <link
                        rel="canonical"
                        href="https://www.theliftedmedia.com/"
                      />
                      <meta name="robots" content="index, follow" />
                    </Helmet>
                    <div className="absolute left-1/2 -translate-x-1/2 z-50">
                      {isLoadingComplete && (
                        <HomeIconLink
                          navigate={navigate}
                          setCurrentSection={setCurrentSection}
                          swatch={swatch}
                          className={`${
                            currentSection === 0 ||
                            (currentSection === 3 && deviceType === "desktop")
                              ? "opacity-100"
                              : "opacity-0 pointer-events-none"
                          }`}
                        />
                      )}
                    </div>

                    {sections.map((getSection, index) => (
                      <div
                        key={index}
                        style={{
                          opacity:
                            index === currentSection
                              ? currentSectionOpacity
                              : 0,
                        }}
                        className={`section absolute w-full lg:h-[95%] flex justify-center transition-opacity ${
                          isMenuOpen ? "duration-100" : "duration-1000"
                        } duration-1000 items-center ${
                          index === currentSection
                            ? "opacity-100 pointer-events-all z-10"
                            : "opacity-0 pointer-events-none z-0"
                        }
                ${browserName === "firefox" ? "h-[95%]" : "h-[90%]"}
                ${deviceType === "tablet" ? "h-full" : "h-[90%]"}
                `}
                      >
                        {getSection()}

                        {index === 0 && currentSection === 0 && (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            href="#"
                            className={`${styles.scrollArrow}`}
                            onClick={scrollToNextSection}
                            onMouseOver={playSoundAnchors}
                            onMouseLeave={stopSoundAnchors}
                          >
                            <span>
                              <i data-title="Scroll">Scroll</i>
                            </span>
                          </a>
                        )}

                        {index === sections.length - 1 && (
                          <Footer
                            styles={styles}
                            playSoundAnchors={playSoundAnchors}
                            stopSoundAnchors={stopSoundAnchors}
                            playSoundLinks={playSoundLinks}
                            stopSoundLinks={stopSoundLinks}
                          />
                        )}
                      </div>
                    ))}
                  </>
                }
              />
              <Route
                path="/404"
                element={
                  <>
                    <Helmet>
                      <link
                        rel="canonical"
                        href="https://www.theliftedmedia.com/404"
                      />
                    </Helmet>
                    <NotFoundPage />
                  </>
                }
              />
              <Route path="*" element={<RedirectTo404 />} />
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
