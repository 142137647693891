import digiGlobeImg from "../imgs/DigiGlobeSite.webp";
import madalgoImg from "../imgs/MADALGOSite.webp";
import marshmallowImg from "../imgs/MARSHMALLOWSite.webp";
import dibzImg from "../imgs/DibzSite.webp";

const featuredWorks = [
  {
    name: "DIGI GLOBE",
    url: "https://www.digi-globe.com",
    img: digiGlobeImg,
    label: "Digi Globe",
  },
  {
    name: "MADALGO",
    url: "https://www.madalgo.io",
    img: madalgoImg,
    label: "MADALGO",
  },
  {
    name: "MARSHMALLOW",
    url: "https://www.marshmallownft.com",
    img: marshmallowImg,
    label: "MARSHMALLOW",
  },
  {
    name: "WE CALL DIBZ",
    url: "https://wecalldibz.com",
    img: dibzImg,
    label: "Dibz",
  },
];

export default featuredWorks;
