import React, { useState, useEffect } from "react";
import styles from "./css/Loading.module.css";

const Loading = ({ onLoadingComplete, deviceType }) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [typedCharacters, setTypedCharacters] = useState([]);
  const [typingComplete, setTypingComplete] = useState(false);
  const [showPercentageCounter, setShowPercentageCounter] = useState(false);
  const [loaderOpacity, setLoaderOpacity] = useState(0);

  const fullText = `          
#^&          ^5##@             @^##!   #^&#^&#^&###   
^5#          &&HN%$         $6#^###!   #^&      #^&  
^5#          B^H  #^$      #^#   FG!   #^&      #^&  
H@$          ^#%   #^$    #^#    ##!   #^&      #^&  
H%&          F&$    #^$  #^#     #!!   #^&^& #^&4#! 
B^#          ^$#     #^$#^$      @!!   #^& 
^$%          H@#                 ##!   #^& 
^&H          ^$#                 ^G!   #^& 
^5##*HJU^#   ^&$                 ^G!   #^& 
%#^UJH*###   H@#                 &!!   #^& 

// Prepare pages...
var pagesToLoad = [
  'vision',
  'portfolio',
  'people',
  'content',
  'careers',
  'contact',
];


function load() {
  showLoader();
  loadPages(pagesToLoad, onPagesLoaded);
}


function onPagesLoaded() {
  hideLoader();

  // Show intro if first time, otherwise show content
  if(firstTime) {
    showIntro();
  } else {
    showContent();
  }
}


// Loading website...
load();
`;

  useEffect(() => {
    let index = 0;
    const typingSpeed = deviceType === "desktop" ? 0 : 6.63;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setTypedCharacters((prev) => [
          ...prev,
          <span key={index} className={styles.fadeIn}>
            {fullText.charAt(index)}
          </span>,
        ]);
        index++;
      } else {
        setShowPercentageCounter(true);
        clearInterval(interval);
        setTimeout(() => {
          setLoaderOpacity(1);
        }, 500);
        setTimeout(() => {
          setTypingComplete(true);
        }, 700);
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [fullText, deviceType]);

  useEffect(() => {
    if (typingComplete) {
      const percentageSpeed = 30;
      const interval = setInterval(() => {
        setLoadingPercentage((oldPercentage) => {
          if (oldPercentage >= 99) {
            clearInterval(interval);
            return 99;
          }
          return oldPercentage + 1;
        });
      }, percentageSpeed);

      return () => clearInterval(interval);
    }
  }, [typingComplete]);

  useEffect(() => {
    if (loadingPercentage >= 99 && typingComplete) {
      setTimeout(() => {
        if (onLoadingComplete) onLoadingComplete();
      }, 500);
    }
  }, [loadingPercentage, typingComplete, onLoadingComplete]);

  return (
    <div className={`${styles.loader} loading-container`}>
      <div className={styles.loadingWrapperContainer}>
        <pre
          className={`${styles.preLoader} ${styles.label}`}
          aria-disabled="true"
        >
          {typedCharacters}
        </pre>
        {showPercentageCounter && (
          <span
            className={`${styles.preLoaderCounter} ${styles.headingLoader}`}
            style={{ opacity: loaderOpacity, visibility: "inherit" }}
          >
            <div className={styles.counter}>Loading Percentage:</div>
            {loadingPercentage}
          </span>
        )}
      </div>
    </div>
  );
};

export default Loading;
