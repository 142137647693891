import { gsap } from "gsap";
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

const Borders = (props, ref) => {
  const { strokeColor } = props;
  const svgRef = useRef(null);

  const triggerGlitchEffect = () => {
    const svg = svgRef.current;
    const elements = svg.querySelectorAll("line, path, polyline");

    gsap.to(elements, {
      duration: 0.1,
      autoAlpha: 0,
      repeat: 3,
      ease: "rough({strength: 2, points: 20, randomize: true})",
      onComplete: () => {
        // Reset to different autoAlpha values for path/lines and polylines
        const pathsAndLines = svg.querySelectorAll("path, line");
        const polylines = svg.querySelectorAll("polyline");

        gsap.to(pathsAndLines, { autoAlpha: 0.16 }); // For paths and lines
        gsap.to(polylines, { autoAlpha: 1 }); // For polylines
      },
    });
  };

  // Expose triggerGlitchEffect to parent via ref
  useImperativeHandle(ref, () => ({
    triggerGlitchEffect,
  }));

  const calculateLineLength = (line) => {
    const x1 = parseFloat(line.getAttribute("x1"));
    const y1 = parseFloat(line.getAttribute("y1"));
    const x2 = parseFloat(line.getAttribute("x2"));
    const y2 = parseFloat(line.getAttribute("y2"));
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  const updateSvgDimensions = () => {
    const svg = svgRef.current;
    if (!svg) return false;

    const newWidth = window.innerWidth;
    const newHeight = window.innerHeight;

    svg.setAttribute("viewBox", `0 0 ${newWidth} ${newHeight}`);

    const lines = svg.querySelectorAll("line");
    const path = svg.querySelector("path.st0");

    if (lines.length >= 3 && path) {
      // Right Vertical Line (lines[0])
      lines[0].setAttribute("x1", newWidth - 0.5);
      lines[0].setAttribute("y1", "30");
      lines[0].setAttribute("x2", newWidth - 0.5);
      lines[0].setAttribute("y2", newHeight - 30);

      // Left Vertical Line (lines[1])
      lines[1].setAttribute("x1", "0.5");
      lines[1].setAttribute("y1", "30");
      lines[1].setAttribute("x2", "0.5");
      lines[1].setAttribute("y2", newHeight - 30);

      // Bottom Horizontal Line (lines[2])
      lines[2].setAttribute("x1", "30");
      lines[2].setAttribute("y1", newHeight - 0.5);
      lines[2].setAttribute("x2", newWidth - 30);
      lines[2].setAttribute("y2", newHeight - 0.5);

      // Update path
      const newPath = `M26.28,0.5 H${newWidth - 26.28}`; // Starting from top left
      path.setAttribute("d", newPath);

      // Set stroke dasharray and dashoffset for path and lines
      Array.from(lines).forEach((line) => {
        const length = calculateLineLength(line);
        line.style.strokeDasharray = length;
        line.style.strokeDashoffset = length;
      });

      const pathLength = path.getTotalLength();
      path.style.strokeDasharray = pathLength;
      path.style.strokeDashoffset = pathLength;
    }

    // Update polylines
    const polylines = svg.querySelectorAll("polyline");
    if (polylines.length >= 4) {
      polylines[1].setAttribute(
        "points",
        `${newWidth - 0.5 - 14.5},0.5 ${newWidth - 0.5 - 8.7},0.5 ${
          newWidth - 0.5
        },9.2 ${newWidth - 0.5},15`
      );
      polylines[2].setAttribute(
        "points",
        `${newWidth - 0.5}, ${newHeight - 0.5 - 14.5} ${newWidth - 0.5}, ${
          newHeight - 0.5 - 8.7
        } ${newWidth - 0.5 - 8.7}, ${newHeight - 0.5}, ${
          newWidth - 0.5 - 14.5
        }, ${newHeight - 0.5}`
      );
      polylines[3].setAttribute(
        "points",
        `15, ${newHeight - 0.5} 9.2, ${newHeight - 0.5} 0.5, ${
          newHeight - 0.5 - 8.7
        } 0.5, ${newHeight - 0.5 - 14.5}`
      );
    }

    return true;
  };

  useEffect(() => {
    const svg = svgRef.current;
    const lines = Array.from(svg.querySelectorAll("line"));
    const path = svg.querySelector("path");
    const polylines = Array.from(svg.querySelectorAll("polyline"));

    const blinkTimeline = gsap.timeline({ repeat: -1, repeatRefresh: true });

    const polylineStaggerDuration = 0.05;
    polylines.forEach((polyline, index) => {
      const startTime = index * polylineStaggerDuration;
      blinkTimeline.fromTo(
        polyline,
        { autoAlpha: 1 },
        {
          autoAlpha: 0.5,
          duration: 0.15,
          ease: "power1.inOut",
          repeat: 1,
          clearProps: "opacity",
        },
        startTime
      );
    });

    const totalPolylineTime =
      polylines.length * polylineStaggerDuration * 2 + 1.5;

    const linesAndPath = [path, lines[0], lines[2], lines[1]];

    const staggerGap = 0.1;

    linesAndPath.forEach((element, index) => {
      const startTime = totalPolylineTime + index * staggerGap;
      blinkTimeline.fromTo(
        element,
        {
          opacity: 0.16,
        },
        {
          opacity: 0.456,
          duration: 0.15,
          ease: "power1.inOut",
          repeat: 1,
          clearProps: "opacity",
        },
        startTime
      );
    });

    const delayBeforeRestart = 2;
    blinkTimeline.to({}, {}, "+=" + delayBeforeRestart);

    return () => {
      if (blinkTimeline) blinkTimeline.kill();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const success = updateSvgDimensions();
      if (success) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      updateSvgDimensions();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="borders z-10">
      <div className="borders-border">
        <svg
          ref={svgRef}
          id="dynamicBorder"
          viewBox="0 0 876 1590"
          width="876px"
          height="1590px"
          fill="none"
          xmlSpace="preserve"
        >
          <path
            className="st0"
            stroke={strokeColor}
            style={{ visibility: "inherit" }}
          ></path>
          <line stroke={strokeColor} style={{ visibility: "inherit" }}></line>
          <line stroke={strokeColor} style={{ visibility: "inherit" }}></line>
          <line stroke={strokeColor} style={{ visibility: "inherit" }}></line>
          <polyline
            stroke={strokeColor}
            points="0.5,15 0.6,9.2 9.3,0.5 15,0.5"
            style={{ visibility: "inherit" }}
          ></polyline>
          <polyline
            stroke={strokeColor}
            style={{ visibility: "inherit" }}
          ></polyline>
          <polyline
            stroke={strokeColor}
            style={{ visibility: "inherit" }}
          ></polyline>
          <polyline
            stroke={strokeColor}
            style={{ visibility: "inherit" }}
          ></polyline>
        </svg>
      </div>
    </div>
  );
};

export default forwardRef(Borders);
