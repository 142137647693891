import React from "react";

const InstagramIcon = () => (
  <svg
    role="img"
    viewBox="0 0 29.75 29.75"
    width="29.75"
    height="29.75"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M24,0H5.75A5.76,5.76,0,0,0,0,5.75V24a5.76,5.76,0,0,0,5.75,5.75H24A5.76,5.76,0,0,0,29.75,24V5.75A5.76,5.76,0,0,0,24,0m1.65,3.43h.66v5h-5l0-5Zm-15,8.39a5.23,5.23,0,1,1-1,3,5.22,5.22,0,0,1,1-3M26.85,24A2.86,2.86,0,0,1,24,26.85H5.75A2.86,2.86,0,0,1,2.9,24V11.82H7.34a7.94,7.94,0,0,0-.6,3,8.14,8.14,0,1,0,15.67-3h4.44Z" />
  </svg>
);

export default InstagramIcon;
