import { useState, useEffect, useRef } from "react";
import styles from "./css/Header.module.css";
import SoundButton from "./parts/SoundToggle";
import XIcon from "./svgs/XIcon";
import YouTubeIcon from "./svgs/YouTubeIcon";
import InstagramIcon from "./svgs/InstagramIcon";
import FacebookIcon from "./svgs/FacebookIcon";
import { Link } from "react-router-dom";

const Header = ({
  deviceType,
  styleOpacity,
  styleVisibility,
  setStyleOpacity,
  setStyleVisibility,
  isMenuOpen,
  setIsMenuOpen,
  setCurrentSection,
  isSoundOn,
  setIsSoundOn,
  audioCtx,
  audioRef,
  animationRunning,
  setAnimationRunning,
  sourceCreated,
  setSourceCreated,
  setCurrentSectionOpacity,
  playSoundAnchors,
  stopSoundAnchors,
  path,
  navigate,
  setSwatch,
  swatch,
}) => {
  const [color, setColor] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [translateY, setTranslateY] = useState("-0.1515px");
  const [scale, setScale] = useState(1.0217);
  const bottomMenuCircleRef = useRef(null);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [soundnPosition, setSoundnPosition] = useState(false);
  const [menuOpacity, setMenuOpacity] = useState(1);
  const [menuVisibility, setMenuVisibility] = useState("");
  const [stylePointerEvents, setStylePointerEvents] = useState("");
  const flickerOpacities = [0.8, 0, 0.8, 0.55, 0, 0.8, 0, 1];
  const [isMenuHovering, setIsMenuHovering] = useState(false);
  const [closeButtonStyle, setCloseButtonStyle] = useState({});
  const [menuTopOpacity, setMenuTopOpacity] = useState(0);
  const menuItems = [
    {
      name: "Featured Work",
      href: "#featured",
      sectionIndex: 1,
      label: "Featured Work",
    },
    {
      name: "Our Services",
      href: "#services",
      sectionIndex: 2,
      label: "Our Services",
    },
    {
      name: "Contact Us",
      href: "#contact",
      sectionIndex: 3,
      label: "Contact Us",
    },
  ];

  const socialLinks = [
    {
      name: "Instagram",
      href: "https://www.instagram.com/esoteric.420/",
      icon: <InstagramIcon />,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/liftedmediaproductions",
      icon: <FacebookIcon />,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/esoterickush",
      icon: <YouTubeIcon />,
    },
    {
      name: "X",
      href: "https://x.com/liftedmedia_llc",
      icon: <XIcon />,
    },
  ];

  useEffect(() => {
    if (isMenuOpen) {
      setMenuTopOpacity(0);
      setCloseButtonStyle({
        opacity: 1,
        visibility: "inherit",
        transform: "none",
      });
      setTimeout(() => {
        setMenuTopOpacity(1);
        setMenuTopOpacity("");
      }, 400);
    } else {
      setMenuTopOpacity(0);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    let intervalId;
    if (isHovering) {
      const colors =
        swatch || path === "/privacy" || path === "/legal"
          ? ["rgb(16, 16, 16)", "rgb(31, 31, 31)", "rgb(14, 14, 14)"]
          : ["rgb(226, 226, 226)", "rgb(248, 248, 248)", "rgb(214, 214, 214)"];
      let currentStage = 0;

      const cycleColors = () => {
        setColor(colors[currentStage % colors.length]);
        currentStage++;

        if (currentStage >= colors.length) {
          clearInterval(intervalId);
        }
      };

      intervalId = setInterval(cycleColors, 50);
    } else {
      clearInterval(intervalId);
      setColor("");
    }

    return () => clearInterval(intervalId);
  }, [isHovering, swatch, path]);

  useEffect(() => {
    let timeout1, timeout2, timeout3;

    if (isMenuHovering) {
      timeout1 = setTimeout(() => {
        setCloseButtonStyle((prevStyle) => ({
          ...prevStyle,
          opacity: 0.5,
        }));
      }, 60);

      timeout2 = setTimeout(() => {
        setCloseButtonStyle((prevStyle) => ({
          ...prevStyle,
          opacity: 1,
        }));
      }, 120);

      timeout3 = setTimeout(() => {
        setCloseButtonStyle({
          opacity: 0.5,
          visibility: "inherit",
          transform: "rotate(180deg)",
        });
      }, 180);
    } else {
      setCloseButtonStyle({});
    }

    return () => {
      // Clear timeouts on cleanup
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, [isMenuHovering]);

  const handleMouseMove = (event) => {
    if (!bottomMenuCircleRef.current) return;

    const circleRect = bottomMenuCircleRef.current.getBoundingClientRect();

    const centerY = circleRect.top + circleRect.height * 0.2;
    const distanceFromCenterY = Math.abs(event.clientY - centerY);

    let newTranslateY =
      ((circleRect.height - distanceFromCenterY) / circleRect.height) * 2.984;
    newTranslateY = Math.min(newTranslateY, 2.984).toFixed(4);
    setTranslateY(`-${newTranslateY}px`);

    const maxScale = 1.4;
    let newScale =
      1 + (1 - distanceFromCenterY / circleRect.height) * (maxScale - 1);
    newScale = Math.min(newScale, maxScale).toFixed(4);
    setScale(newScale);

    const x = event.clientX;
    const y = event.clientY;
    setMouseX(x);
    setMouseY(y);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setTranslateY("-0.1515px");
    setScale(1.0217);
  };

  const handleMouseClick = () => {
    setIsMenuOpen(true);
    setStyleOpacity(0.8);
    setStylePointerEvents("none");
    setMenuOpacity(0);
    setMenuVisibility("hidden");
    setCurrentSectionOpacity(0);

    flickerOpacities.forEach((opacity, index) => {
      setTimeout(() => {
        setMenuOpacity("");
        setMenuVisibility(opacity === 0 ? "hidden" : "");
        setStyleOpacity(0);
        setStyleVisibility("hidden");
        setStylePointerEvents("");
      }, index * 10);
    });
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setMenuOpacity(1);
    setMenuVisibility("inherit");
    setStyleOpacity(1);
    setStyleVisibility("inherit");
    setMenuTopOpacity(0);
    setCurrentSectionOpacity(1);
  };

  useEffect(() => {
    setSoundnPosition(deviceType === "desktop" || deviceType === "tablet");
    const handleDocumentMouseMove = (event) => {
      setMouseX(event.clientX);
      setMouseY(event.clientY);
    };

    document.addEventListener("mousemove", handleDocumentMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
    };
  }, [deviceType]);

  const handleLinkClick = (event, sectionIndex) => {
    if (path !== "/") {
      navigate("/");
      handleMenuClose();
      setCurrentSection(sectionIndex);
    } else {
      event.preventDefault();
      handleMenuClose();
      setCurrentSection(sectionIndex);
    }
  };

  const handleSoundButtonMenu = () => {
    if (deviceType !== "desktop") {
      return (
        <SoundButton
          isMenuOpen={isMenuOpen}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          audioCtx={audioCtx}
          audioRef={audioRef}
          animationRunning={animationRunning}
          setAnimationRunning={setAnimationRunning}
          sourceCreated={sourceCreated}
          setSourceCreated={setSourceCreated}
        />
      );
    }
    return null;
  };

  const handlePrivacyLegalClick = () => {
    setSwatch(true);
    handleMenuClose();
    document.body.className = "";
  };

  return (
    <>
      <div
        className={styles.header}
        style={{ opacity: styleOpacity, visibility: styleVisibility }}
      >
        {soundnPosition && (
          <SoundButton
            isMenuOpen={isMenuOpen}
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
            audioCtx={audioCtx}
            audioRef={audioRef}
            animationRunning={animationRunning}
            setAnimationRunning={setAnimationRunning}
            sourceCreated={sourceCreated}
            setSourceCreated={setSourceCreated}
            playSoundAnchors={playSoundAnchors}
            stopSoundAnchors={stopSoundAnchors}
            swatch={swatch}
            path={path}
          />
        )}
        <div
          className={
            swatch ? styles.menuButtonWrapperInner : styles.menuButtonWrapper
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ color: color }}
        >
          <button
            aria-label="Open Menu"
            className={styles.buttonButton}
            onClick={handleMouseClick}
            onMouseOver={playSoundAnchors}
            onMouseLeave={stopSoundAnchors}
          >
            <div
              ref={bottomMenuCircleRef}
              className={styles.bottomMenuCircle}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              style={{
                transform: `translate(-50%, 0%) translate(0px, ${translateY})`,
                transition: "transform 0.3s ease",
              }}
            ></div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 5"
              style={{
                transform: `translate(-16px, -2.5px) scale(${scale}, 1)`,
                transition: "transform 0.3s ease",
              }}
              xmlSpace="preserve"
              className={
                swatch ? styles.bottomMenuBurgerInner : styles.bottomMenuBurger
              }
            >
              <path d="M5 0h20v1H5zM5 4h20v1H5z"></path>
            </svg>
          </button>
        </div>
        {soundnPosition && (
          <div
            className={`${styles.headerMouse} ${
              swatch ? styles.mousePositionInner : styles.mousePosition
            }`}
          >
            <div className="Copy_sizeLabelS__zNyoY">
              X {mouseX.toString().padStart(4, "0")} / Y{" "}
              {mouseY.toString().padStart(4, "0")}
            </div>
          </div>
        )}
      </div>

      {isMenuOpen && (
        <div
          className={styles.menuOverlay}
          style={{
            opacity: menuOpacity,
            visibility: menuVisibility,
            pointerEvents: stylePointerEvents,
          }}
        >
          <div
            className={styles.menuTop}
            style={{ opacity: menuTopOpacity, transition: "opacity 0.3s ease" }}
          >
            <button
              id="close-menu-button"
              className={`${styles.buttonButton} ${styles.menuCloseButton}`}
              onMouseEnter={() => setIsMenuHovering(true)}
              onMouseLeave={() => setIsMenuHovering(false)}
              onClick={handleMenuClose}
              style={closeButtonStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
                className={styles.menuCloseButtonIcon}
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
              >
                <path d="M1 19 19 1M1 1l9 9M19 19l-7-7" stroke="#000"></path>
              </svg>
            </button>
            <div className={`${styles.socialMenu} ${styles.socialMenus}`}>
              {socialLinks.map((link) => (
                <a
                  key={link.name}
                  className={`${styles.Link} ${styles.socialLink}`}
                  href={link.href}
                  rel="noreferrer"
                  target="_blank"
                  aria-label={`Follow us on ${link.name}`}
                  onMouseOver={playSoundAnchors}
                  onMouseLeave={stopSoundAnchors}
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
          <div
            className={styles.menuMiddle}
            style={{ opacity: menuTopOpacity, transition: "opacity 0.3s ease" }}
          >
            <ul>
              {menuItems.map((item, index) => (
                <li
                  key={item.name}
                  style={{ animationDelay: `${index * 0.3}s` }}
                  className={styles.fadeInAnimation}
                >
                  <a
                    href={item.href}
                    onClick={(e) => handleLinkClick(e, item.sectionIndex)}
                    aria-label={item.label}
                    onMouseOver={playSoundAnchors}
                    onMouseLeave={stopSoundAnchors}
                  >
                    <span
                      className={`${styles.linkText} ${styles.Link}`}
                      data-text={item.name}
                    >
                      {item.name}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div
            className={styles.menuBottom}
            style={{ opacity: menuTopOpacity, transition: "opacity 0.3s ease" }}
          >
            <div className={styles.copyrightLabel}>
              Copyright© 2024 All rights reserved
            </div>
            <div className={styles.menuLink}>
              {handleSoundButtonMenu()}
              <Link
                className={styles.textMenuLink}
                to="/privacy"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
                onClick={handlePrivacyLegalClick}
              >
                Privacy Policy
              </Link>
              <Link
                className={styles.textMenuLink}
                to="/legal"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
                onClick={handlePrivacyLegalClick}
              >
                Legal Disclaimer
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
