import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel, EffectCards } from "swiper/modules";
import SMM from "../imgs/smm.webp";
import GraphicDesign from "../imgs/GraphicDesign.webp";
import SEO from "../imgs/SEO.webp";
import DigiGlobeWebsite from "../imgs/DigiGlobeSite.webp";
import DroneContent from "../imgs/Drone.webp";
import Photography from "../imgs/Photography.webp";
import PR from "../imgs/PR.webp";
import Videography from "../imgs/Videography.webp";

import "swiper/swiper-bundle.css";

const SwiperComponent = ({ setDescription, browserName, deviceType }) => {
  const slidesData = [
    {
      imgSrc: SMM,
      title: "Social Media Management",
      description:
        "Harness the power of social media with our comprehensive management services. We specialize in crafting targeted campaigns that boost your visibility, engage your audience, and drive conversions. From strategy development to content creation, scheduling, and analytics, we manage every aspect to ensure your brand stands out in the crowded social media landscape.",
    },
    {
      imgSrc: GraphicDesign,
      title: "Graphic Design",
      description:
        "Elevate your brand with our professional graphic design services. Our team of creative experts brings your vision to life with stunning designs that communicate your message effectively. Whether you need logos, brochures, banners, or complete corporate identity kits, we deliver high-quality designs that captivate and inspire.",
    },
    {
      imgSrc: SEO,
      title: "SEO",
      description:
        "Improve your website's visibility and rank higher in search engine results with our tailored SEO strategies. Our services include keyword research, on-page optimization, link building, and performance analytics to ensure your site attracts and retains high-quality traffic.",
    },
    {
      imgSrc: DigiGlobeWebsite,
      title: "Web Design & Development",
      description:
        "Build a strong online presence with our website design and development services. We create responsive, user-friendly, and visually appealing websites tailored to your business needs. From e-commerce solutions to custom web applications, our team ensures your website delivers a seamless user experience on any device.",
    },
    {
      imgSrc: Photography,
      title: "Photography",
      description:
        "Capture the essence of your brand with our professional photography services. Our skilled photographers specialize in commercial, product, and event photography, delivering high-quality images that enhance your marketing materials and digital presence.",
    },
    {
      imgSrc: Videography,
      title: "Videography",
      description:
        "Tell your brand’s story with compelling video content. Our videography services include corporate videos, promotional clips, and event coverage that engage and inform your audience. With state-of-the-art equipment and creative editing, we help you make a lasting impact.",
    },
    {
      imgSrc: DroneContent,
      title: "Drone Content Services",
      description:
        "Take your visual content to new heights with our drone content services. Ideal for real estate, event coverage, and promotional videos, our drone footage offers a unique perspective that captivates viewers. Our certified operators ensure safe and stunning aerial shots that elevate your project.",
    },
    {
      imgSrc: PR,
      title: "PR(Public Relations)",
      description:
        "Strengthen your public relations with our expert services. We help you build a positive brand image and maintain strong media relations. Our PR services include press release distribution, event management, crisis communication, and media training to ensure your message reaches the right audience.",
    },
  ];

  const [swiperStyle, setSwiperStyle] = useState({
    width: "200px",
    height: "300px",
  });

  useEffect(() => {
    const updateStyle = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      let newStyles = {};
      if (deviceType === "desktop") {
        if (width > 1200) {
          newStyles.width = height > 800 ? "600px" : "400px";
        } else {
          newStyles.width = "50%";
        }
        newStyles.height = height > 800 ? "700px" : "100%";
        newStyles.padding = "50px 0";
      } else if (deviceType === "tablet") {
        newStyles.width = width > 1024 ? "420px" : "50%";
        newStyles.height = width > 1024 ? "90%" : "50%";
        newStyles.padding = "40px 0";
      } else {
        if (browserName === "firefox") {
          newStyles.padding = "20px 0";
        } else {
          newStyles.padding = "0px 0";
        }
        newStyles.width = "200px";
        newStyles.height = "300px";
      }

      setSwiperStyle(newStyles);
    };

    updateStyle();
    window.addEventListener("resize", updateStyle);

    return () => window.removeEventListener("resize", updateStyle);
  }, [deviceType, browserName]);

  return (
    <Swiper
      modules={[Navigation, Mousewheel, EffectCards]}
      effect="cards"
      grabCursor={true}
      initialSlide={3}
      speed={500}
      loop={true}
      navigation={false}
      mousewheel={false}
      className="swiper-container"
      style={swiperStyle}
      onSlideChange={(swiper) => {
        const realIndex = swiper.realIndex;
        setDescription(slidesData[realIndex].description);
      }}
    >
      {slidesData.map((slide, index) => (
        <SwiperSlide key={index}>
          <img
            src={slide.imgSrc}
            alt={slide.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fit",
              position: "absolute",
              inset: 0,
            }}
          />
          <div className="overlay absolute inset-0 w-full h-full bg-no-repeat bg-cover [background:linear-gradient(to_top,_#0c0d2b,_transparent,_transparent)]">
            <h2
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                color: "#fff",
                fontWeight: "400",
                fontSize: "1.1rem",
                lineHeight: "1.4",
                margin: "0 0 20px 20px",
              }}
            >
              {slide.title}
            </h2>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
