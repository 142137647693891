import React from "react";
import HomeIconLink from "./HomeIconLink";

const PrivacyPolicy = ({ navigate, setCurrentSection, swatch }) => {
  return (
    <>
      <div className="absolute left-1/2 -translate-x-1/2 z-50">
        <HomeIconLink
          navigate={navigate}
          setCurrentSection={setCurrentSection}
          swatch={swatch}
        />
      </div>
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex flex-col items-center justify-center w-[90%] h-[90%] text-[.5rem] md:text-md lg:text-lg">
          <div className="mt-8 lg:mt-28">
            <h1 className="font-bold text-center">Privacy Policy</h1>
            <article className="md:max-w-4xl lg:max-w-full mx-auto mt-4 space-y-3 max-w-72 overflow-auto h-[70%] 3xl:h-full max-h-[66vh] lg:max-h-[70vh] xl:max-h-[80vh] 3xl:max-h-screen p-4">
              <section>
                <h2>1. Information Collection</h2>
                <p>
                  We collect information from you when you register on our site,
                  place an order, subscribe to our newsletter, fill out a form,
                  or enter information on our site.
                </p>
              </section>
              <section>
                <h2>2. Use of Information</h2>
                <p>
                  Any of the information we collect from you may be used in one
                  of the following ways:
                </p>
                <ul className="list-disc pl-5">
                  <li>
                    To personalize your experience and to allow us to deliver
                    the type of content and product offerings in which you are
                    most interested.
                  </li>
                  <li>To improve our website in order to better serve you.</li>
                  <li>
                    To allow us to better service you in responding to your
                    customer service requests.
                  </li>
                  <li>
                    To administer a contest, promotion, survey or other site
                    feature.
                  </li>
                  <li>To quickly process your transactions.</li>
                  <li>
                    To send periodic emails regarding your order or other
                    products and services.
                  </li>
                </ul>
              </section>
              <section>
                <h2>3. Protection of Information</h2>
                <p>
                  We implement a variety of security measures to maintain the
                  safety of your personal information when you place an order or
                  enter, submit, or access your personal information.
                </p>
              </section>
              <section>
                <h2>4. Disclosure of Information to Third Parties</h2>
                <p>
                  We do not sell, trade, or otherwise transfer to outside
                  parties your personally identifiable information unless we
                  provide you with advance notice, except as described below.
                  The term "outside parties" does not include Lifted Media
                  Productions. It also does not include website hosting partners
                  and other parties who assist us in operating our website,
                  conducting our business, or servicing you, so long as those
                  parties agree to keep this information confidential.
                </p>
              </section>
              <section>
                <h2>5. Third Party Links</h2>
                <p>
                  Occasionally, at our discretion, we may include or offer third
                  party products or services on our website. These third party
                  sites have separate and independent privacy policies. We
                  therefore have no responsibility or liability for the content
                  and activities of these linked sites. Nonetheless, we seek to
                  protect the integrity of our site and welcome any feedback
                  about these sites.
                </p>
              </section>
              <section>
                <h2>6. Consent</h2>
                <p>By using our site, you consent to our privacy policy.</p>
              </section>
              <section>
                <h2>7. Changes to our Privacy Policy</h2>
                <p>
                  If we decide to change our privacy policy, we will post those
                  changes on this page. This policy was last modified on 5/8/24
                </p>
              </section>
              <section>
                <h2>Contact Us</h2>
                <p>
                  If there are any questions regarding this privacy policy you
                  may contact us using the information below.
                </p>
                <p>
                  <a href="https://www.theliftedmedia.com">
                    https://www.theliftedmedia.com
                  </a>
                </p>
                <p>liftedmediaproductionsllc@gmail.com</p>
              </section>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
