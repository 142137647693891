import styles from "./css/Footer.module.css";
import FacebookIcon from "./svgs/FacebookIcon";
import InstagramIcon from "./svgs/InstagramIcon";
import XIcon from "./svgs/XIcon";
import YouTubeIcon from "./svgs/YouTubeIcon";
import Contact from "./components/Contact";

const Footer = ({
  playSoundAnchors,
  stopSoundAnchors,
  playSoundLinks,
  stopSoundLinks,
}) => {
  return (
    <div
      className={`${styles.footer} bg-black border-white bg-opacity-60 border-opacity-10`}
    >
      <div className="w-full h-full">
        <div className="flex flex-row justify-evenly w-full lg">
          <div className="w-full hidden md:flex items-center justify-center">
            <div className="max-w-96 lg:max-w-128 xl:max-w-144">
              <p className="text-2xl 2xl:text-4xl font-light text-left">
                Elevate your brand with Lifted Media Productions. Specializing
                in photography, videography, website design, SEO, and social
                media management, we deliver impactful digital solutions
                tailored to showcase your vision with creativity and precision.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-evenly gap-10 w-full items-center">
            <Contact
              playSoundLinks={playSoundLinks}
              stopSoundLinks={stopSoundLinks}
            />
          </div>
        </div>
        <div className="footer-foot w-full">
          <ul className="flex justify-evenly w-full flex-row gap-4 list-none 2xl:mb-20">
            <li className="hover:bg-red-500 cursor-pointer w-full h-36 p-4 px-6 lg:px-4">
              <a
                href="https://www.facebook.com/liftedmediaproductions"
                className="2.5xl:text-[2rem] text-2xl"
                aria-label="Facebook"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
              >
                <div className="md:border-t-[1px] w-full h-full border-white justify-center md:justify-between flex items-center">
                  <p className="hidden md:block">Facebook</p>
                  <FacebookIcon />
                </div>
              </a>
            </li>
            <li className="hover:bg-red-500 cursor-pointer w-full h-36 p-4">
              <a
                href="https://www.instagram.com/esoteric.420/"
                className="2.5xl:text-[2rem] text-2xl"
                aria-label="Instagram"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
              >
                <div className="md:border-t-[1px] w-full h-full border-white justify-center md:justify-between flex items-center">
                  <p className="hidden md:block">Instagram</p>
                  <InstagramIcon />
                </div>
              </a>
            </li>
            <li className="hover:bg-red-500 cursor-pointer w-full h-36 p-4 pl-5 lg:pl-4">
              <a
                href="https://twitter.com/liftedmedia_llc"
                className="2.5xl:text-[2rem] text-2xl"
                aria-label="Twitter / X"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
              >
                <div className="md:border-t-[1px] w-full h-full border-white justify-center md:justify-between flex items-center">
                  <p className="hidden md:block">X</p>
                  <XIcon />
                </div>
              </a>
            </li>
            <li className="hover:bg-red-500 cursor-pointer w-full h-36 p-4">
              <a
                href="https://www.youtube.com/esoterickush"
                className="2.5xl:text-[2rem] text-2xl"
                aria-label="YouTube"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
              >
                <div className="md:border-t-[1px] w-full h-full border-white  justify-center md:justify-between flex items-center">
                  <p className="hidden md:block">YouTube</p>
                  <YouTubeIcon />
                </div>
              </a>
            </li>
          </ul>

          <div className="credits flex justify-between text-xs">
            Copyright ©︎ Lifted Media Productions
            <div className="fade">
              Website by{" "}
              <a
                href="https://theliftedmedia.com"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:text-red-500"
                aria-label="The Lifted Media"
                onMouseOver={playSoundAnchors}
                onMouseLeave={stopSoundAnchors}
              >
                LMP
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
