import React, { useState } from "react";
import styles from "../css/Footer.module.css";

import { Amplify } from "aws-amplify";
import { post } from "@aws-amplify/api";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

export default function Contact({ playSoundLinks, stopSoundLinks }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const hasLink = /https?:\/\/|www\./i;
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("idle");

  const resetForm = () => {
    setHoneypot("");
    setSubmissionStatus("idle");
  };

  const ArrowIcon = () => (
    <svg
      className="pt-1"
      width="100%"
      height="100%"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H19M19 5L14.6667 1M19 5L14.6667 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );

  const Spinner = () => {
    return (
      <div className={`${styles.spinnerContainer}`}>
        <div className={`${styles.spinner}`}></div>
      </div>
    );
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (honeypot) {
      return;
    }

    let hasError = false;
    let timer;

    if (!name) {
      setNameError("Name is required.");
      hasError = true;
    }

    if (!email) {
      setEmailError("Email is required.");
      hasError = true;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Invalid email address.");
      hasError = true;
    }

    if (!message) {
      setMessageError("Message is required.");
      hasError = true;
    }

    if (hasLink.test(name)) {
      setNameError("Links are not allowed in name.");
      hasError = true;
    }

    if (hasLink.test(message)) {
      setMessageError("Links are not allowed in message.");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setSubmissionStatus("loading");
    setNameError("");
    setEmailError("");
    setMessageError("");

    const apiName = "api555b51f6";
    const path = "/contact";
    const body = {
      body: {
        name: name,
        email: email,
        message: message,
      },
    };

    try {
      const { response } = post({
        apiName,
        path,
        options: {
          body,
          headers: { "Content-Type": "application/json" },
        },
      });

      const data = await response;
      console.log(data);
      setSubmissionStatus("success");
      setEmail("");
      setName("");
      setMessage("");
      timer = setTimeout(() => {
        resetForm();
      }, 3000);
    } catch (error) {
      console.error(error);
      setSubmissionStatus("error");
    }
    return () => clearTimeout(timer);
  }

  return (
    <div className={`${styles.mainContactWrapper}`}>
      <div className={`${styles.mainContactInner}`}>
        <div className={`${styles.contactFormContainer}`}>
          <h1 className={`${styles.contactUsHeading}`}>Contact Us!</h1>

          <form
            id="contact-us-form"
            className=""
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className={`group ${styles.nameInputField}`}>
              <input
                type="text"
                name="honeypot"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                style={{ display: "none" }}
              />

              <input
                type="text"
                required
                maxLength="50"
                minLength="2"
                className={`group-hover:border-red-500 ${styles.inputField} ${
                  name ? styles.redBorder : ""
                }`}
                placeholder="placeholder"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label
                className={`${name ? styles.activeLabel : styles.inputLabel}`}
              >
                name
              </label>
              <p className={`${styles.errorMessage}`}>{nameError}</p>
            </div>

            <div className={`group ${styles.emailInputField}`}>
              <input
                type="email"
                required
                className={`group-hover:border-red-500 ${styles.inputField} ${
                  email ? styles.redBorder : ""
                }`}
                placeholder="placeholder"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label
                className={`${email ? styles.activeLabel : styles.inputLabel}`}
              >
                email
              </label>
              <p className={`${styles.errorMessage}`}>{emailError}</p>
            </div>

            <div className={`group ${styles.messageInputField}`}>
              <textarea
                rows="4"
                required
                maxLength="500"
                minLength="2"
                className={`group-hover:border-red-500 ${styles.inputField} ${
                  message ? styles.redBorder : ""
                }`}
                placeholder="placeholder"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <label
                className={`${
                  message ? styles.activeLabel : styles.inputLabel
                }`}
              >
                message
              </label>
              <p className={`${styles.errorMessage}`}>{messageError}</p>
            </div>

            <button
              className={`group ${styles.submitButton}`}
              disabled={submissionStatus === "loading"}
              onMouseOver={playSoundLinks}
              onMouseLeave={stopSoundLinks}
            >
              {submissionStatus === "idle" && (
                <>
                  <span className="transition-[all_.3s_easeInOut] group-hover:scale-110">
                    Send
                  </span>
                  <span className="ml-2 h-[19.2px] w-4 transition-[all_.3s_easeInOut] group-hover:scale-110">
                    <ArrowIcon />
                  </span>
                </>
              )}
              {submissionStatus === "loading" && <Spinner />}{" "}
              {submissionStatus === "success" && "Message Sent Successfully"}{" "}
              {submissionStatus === "error" && "Error Sending Message"}{" "}
            </button>
            <p className={`${styles.errorMessage}`}>{messageError}</p>
          </form>
        </div>
      </div>
    </div>
  );
}
