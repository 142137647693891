import React from "react";

const XIcon = () => (
  <svg
    viewBox="0 0 31.89 27.9"
    width="31.89"
    height="27.9"
    aria-hidden="true"
    fill="#fff"
    className="mt-1 ml-1 h-10 w-10"
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
  </svg>
);

export default XIcon;
