import { Link, useLocation } from "react-router-dom";
import LMPIconWhite from "../imgs/LMPIconWhite.webp";
import LMPIconBlack from "../imgs/LMPIconBlack.webp";

const HomeIconLink = ({
  navigate,
  setCurrentSection,
  className = "",
  swatch,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const handleIconClick = (event) => {
    if (swatch || path === "/privacy" || path === "/legal") {
      navigate("/");
      setCurrentSection(0);
      document.body.className = "overflow-hidden";
    } else {
      event.preventDefault();
      setCurrentSection(0);
    }
  };

  const icon =
    path === "/privacy" || path === "/legal" ? LMPIconBlack : LMPIconWhite;
  return (
    <Link to="/" onClick={handleIconClick}>
      <img
        className={`cursor-pointer w-8 lg:w-20 pt-10 transition-opacity duration-1000 ${className}`}
        src={icon}
        alt="Lifted Media Productions Icon"
      />
    </Link>
  );
};

export default HomeIconLink;
