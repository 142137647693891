import React from "react";

const FacebookIcon = () => (
  <svg
    role="img"
    viewBox="0 0 14.76 31.75"
    width="14.76"
    height="31.75"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M14.76,10.28h-5V7a1.34,1.34,0,0,1,1.4-1.53h3.54V0L9.79,0C4.37,0,3.14,4.06,3.14,6.66v3.62H0v5.61H3.14V31.75H9.73V15.89h4.45Z" />
  </svg>
);

export default FacebookIcon;
