import React from "react";
import HomeIconLink from "./HomeIconLink";

const LegalDisclaimer = ({ navigate, setCurrentSection, swatch }) => {
  return (
    <>
      <div className="absolute left-1/2 -translate-x-1/2 z-50">
        <HomeIconLink
          navigate={navigate}
          setCurrentSection={setCurrentSection}
          swatch={swatch}
        />
      </div>
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex flex-col items-center justify-center w-[90%] h-[90%] text-[.5rem] md:text-md lg:text-lg">
          <div className="mt-8 lg:mt-28">
            <h1 className="font-bold text-center">Legal Disclaimer</h1>
            <article className="md:max-w-4xl lg:max-w-full mx-auto mt-4 space-y-3 max-w-72 overflow-auto h-[70%] 3xl:h-full max-h-[66vh] lg:max-h-[70vh] xl:max-h-[80vh] 3xl:max-h-screen p-4">
              <section>
                <h2>1. General Information</h2>
                <p>
                  The content on our website is for general information purposes
                  only and does not constitute advice. Lifted Media Productions
                  tries to ensure that the content is accurate and up to date,
                  but this is not guaranteed. The information on this site may
                  change at any time without notice.
                </p>
              </section>
              <section>
                <h2>2. No Professional Advice</h2>
                <p>
                  The information provided on this website does not constitute
                  professional advice, and should not be relied upon without
                  obtaining specific professional advice.
                </p>
              </section>
              <section>
                <h2>3. Warranties and Liability</h2>
                <p>
                  While every effort is made to ensure that the content of this
                  website is accurate, the website is provided “as is” and
                  Lifted Media Productions makes no representations or
                  warranties in relation to the accuracy or completeness of the
                  information found on it. Nothing on this website should be
                  taken to constitute professional advice or a formal
                  recommendation and we exclude all representations and
                  warranties relating to the content and use of this site.
                </p>
              </section>
              <section>
                <h2>4. Links to Other Websites</h2>
                <p>
                  This website may contain links to other websites. Lifted Media
                  Productions is not responsible for the content of third-party
                  websites.
                </p>
              </section>
              <section>
                <h2>5. Copyright and Rights</h2>
                <p>
                  The content on this site is copyrighted. You may not
                  reproduce, modify, copy or distribute any material from this
                  site without express consent from Lifted Media Productions.
                </p>
              </section>
              <section>
                <h2>6. Changes to the Disclaimer</h2>
                <p>
                  We may revise this disclaimer from time to time. The most
                  current version will always be posted on our website.
                </p>
              </section>
              <section>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about this disclaimer, please
                  contact us at:
                </p>
                <p>
                  <a href="https://www.theliftedmedia.com">
                    https://www.theliftedmedia.com
                  </a>
                </p>
                <p>liftedmediaproductionsllc@gmail.com</p>
              </section>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalDisclaimer;
