import React from "react";

const LMPIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.36 202.31">
      <g>
        <path d="M98.71,165.06v37.22H0V0h42.88v165.06h55.83Z" fill="#fff" />
        <path
          d="M110.66,202.28L149.8,3.91h39.65l40.74,107.51L276.41,4.72h42.07l21.95,197.59h-34.79l-16.5-138.52-43.69,97.09-32.36.81-41.13-94.67-26.16,135.12s-35.15.14-35.15.14Z"
          fill="#fff"
        />
        <path
          d="M434.63,3.24h-77.04v.81h-.81v198.23h35.6v-82.53h42.25c26.37,0,47.74-21.37,47.74-47.74v-21.04c0-26.36-21.37-47.74-47.74-47.74ZM447.18,64.32c0,10.5-4.88,23.06-15.38,23.06h-38.91v-46.93h38.91c10.5,0,15.46,13.37,15.46,23.87h-.08Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default LMPIcon;
